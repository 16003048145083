import { useTranslation } from 'react-i18next'
import { dayNames, monthNames, repeatDaysItems, RepeatItem, repeatTimeItems } from 'consts'
import { useMemo } from 'react'

export const useTranslateConsts = () => {
  const { t } = useTranslation()

  const translatedRepeatTimeItems = useMemo(
    (): RepeatItem[] =>
      repeatTimeItems.map((it: RepeatItem) => ({
        ...it,
        name: t(it.name),
      })),
    [t],
  )

  const translatedRepeatDayItems = useMemo(
    (): RepeatItem[] =>
      repeatDaysItems.map((it: RepeatItem) => ({
        ...it,
        name: t(it.name),
      })),
    [t],
  )

  const translatedDayNames = useMemo(() => dayNames.map((it: string) => t(it)), [t])
  const translatedMonthNames = useMemo(() => monthNames.map((it: string) => t(it)), [t])

  return {
    dayNames: translatedDayNames,
    monthNames: translatedMonthNames,
    repeatTimeItems: translatedRepeatTimeItems,
    repeatDaysItems: translatedRepeatDayItems,
  }
}
