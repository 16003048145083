import { useState } from 'react'
import { observer } from 'mobx-react-lite'
import WebApp from '@twa-dev/sdk'

import { UserStore } from 'stores'
import { FlexLayout, PageHeader, Avatar } from 'components'

import FeedbackButton from './FeedbackButton'
import defaultAvatar from './defaultAvatar.png'

import './styles.scss'

const User = observer(() => {
  const [store] = useState(() => new UserStore(WebApp.initDataUnsafe.user))

  return (
    <FlexLayout.Container className="user-page" vertical>
      <Avatar src={store.avatar || defaultAvatar} alt={`${store.fullName}'s avatar`} size={48} />

      <PageHeader className="regular-indent">{store.fullName}</PageHeader>
      <div className="balance">$ {store.formattedBalance}</div>

      <FeedbackButton />
    </FlexLayout.Container>
  )
})

export default User
