import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Outlet, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import WebApp from '@twa-dev/sdk'

import { useApp, useTheme } from 'utils/hooks'
import { Button, PageSpinner } from 'components'
import { translationContext } from 'contexts'

import './styles.scss'

const isTouchable = 'ontouchstart' in window || navigator.maxTouchPoints > 0

const AppLayout = observer(() => {
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  const { initialized } = useApp()

  useEffect(() => {
    translationContext.t = t
  }, [t])

  useEffect(() => {
    if (isTouchable) {
      WebApp.requestFullscreen()
      document.body.classList.add('touchable')
    } else {
      WebApp.expand()
    }
  }, [])

  useEffect(() => {
    const startApp = WebApp.initDataUnsafe.start_param || ''
    const match = startApp.match(/projects_(\d+)/)
    const mathProjectIssues = startApp.match(/projects_(\d+)_issues_(\d+)/)

    if (mathProjectIssues) {
      const projectId = mathProjectIssues[1]
      const issuesId = mathProjectIssues[2]
      navigate(`/projects/${projectId}/tasks/${issuesId}`, { replace: true })
    } else if (match) {
      const projectId = match[1]
      navigate(`/projects/${projectId}`, { replace: true })
    }
  }, [navigate])

  useTheme()

  // TODO: удалить кнопку и функцию после теста и добавления всех значений
  const onChangeLang = () => {
    let lang = ''

    switch (i18n.language) {
      case 'en':
        lang = 'es'
        break
      case 'es':
        lang = 'ru'
        break
      case 'ru':
        lang = 'en'
    }

    i18n.changeLanguage(lang)
  }

  useEffect(() => {
    i18n.changeLanguage(WebApp?.initDataUnsafe?.user?.language_code || 'en')
  }, [i18n])

  return (
    <div className="full-screen">
      <Button
        style={{
          display: 'none', // Удалять для теста перевода
          position: 'fixed',
          zIndex: 1,
          top: isTouchable ? 'var(--mobile-top-indent)' : 0,
        }}
        onClick={onChangeLang}
      >
        {i18n.language}
      </Button>
      {initialized ? <Outlet /> : <PageSpinner delay={300} open opaque />}
    </div>
  )
})

export default AppLayout
