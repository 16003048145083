import { useCallback, useEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { BackButton } from '@twa-dev/sdk/react'
import { observer } from 'mobx-react-lite'
import cn from 'classnames'

import { useValueContext, ValueContext } from 'contexts'
import { isAllDef, isDefAndNotEmpty, useInfiniteScroll } from 'utils'
import {
  FlexLayout,
  GearIcon,
  HeaderWithControl,
  SearchInput,
  TaskFilters,
  PageSpinner,
} from 'components'

import TaskList from './TaskList'
import Footer from './Footer'

import './styles.scss'

const Project = observer(() => {
  const store = useValueContext()

  const listRef = useRef<HTMLDivElement>(null)
  const navigate = useNavigate()
  const { projectId } = useParams()

  const {
    project,
    loading,
    loadIssues,
    saveFilters,
    loadMoreIssues,
    setSearch,
    search,
    config,
    issues,
    sorting,
    selectedFilters,
    pagination,
    loadingMore,
    issuesLoading,
    issuesLoaded,
    hasFilters,
    disabled,
  } = store

  const { assignees, owners, statuses } = selectedFilters

  useEffect(() => {
    pagination.setFirstPage()
  }, [pagination])

  useEffect(() => {
    if (pagination.currentPage > 1) {
      loadMoreIssues()
    }
  }, [pagination.currentPage, loadMoreIssues])

  useEffect(() => {
    const id = Number(projectId)

    const shouldLoadIssues = isAllDef(
      sorting.key,
      sorting.direction,
      project.id,
      assignees,
      owners,
      statuses,
    )

    if (shouldLoadIssues) {
      pagination.setFirstPage()
      saveFilters()
      loadIssues(id)
    }
  }, [
    loadIssues,
    projectId,
    sorting.key,
    sorting.direction,
    project.id,
    assignees,
    owners,
    statuses,
    pagination,
    saveFilters,
  ])

  useEffect(() => {
    if (!listRef.current) {
      return
    }
    if (isDefAndNotEmpty(issues) && pagination.isFirstPage) {
      listRef.current.scrollTop = 0
    }
  }, [issues, pagination.isFirstPage])

  const onScrollToBottom = () => {
    pagination.setNextPage()
  }
  const onScroll = useInfiniteScroll(loadingMore, onScrollToBottom)

  const onSearch = () => {
    const id = Number(projectId)
    if (id) {
      loadIssues(id)
    }
  }

  const onBackClick = useCallback(() => {
    navigate(`/projects`)
  }, [navigate])

  const showFilters = hasFilters || isDefAndNotEmpty(issues)

  return (
    <ValueContext.Provider value={store}>
      <PageSpinner open={loading} />
      <FlexLayout.Container vertical>
        <BackButton onClick={onBackClick} />
        <HeaderWithControl
          className="px-3"
          after={
            <GearIcon
              className="project-settings-icon"
              onClick={() => navigate(`/projects/${projectId}/settings`)}
            />
          }
        >
          {project?.title}
        </HeaderWithControl>

        {showFilters && (
          <div className="px-3">
            <SearchInput value={search} onChange={setSearch} onSearch={onSearch} />
            <TaskFilters />
          </div>
        )}

        <FlexLayout.Content
          className="project-content"
          onScroll={onScroll}
          ref={listRef}
          scrollable
        >
          <TaskList
            items={issues}
            loaded={issuesLoaded}
            loading={issuesLoading}
            hasFilters={hasFilters}
            loadingMore={loadingMore || issuesLoading}
            timeZone={config?.timeZoneOffset}
          />
        </FlexLayout.Content>
        <Footer />
        <div className={cn({ 'project-disabled': disabled })} />
      </FlexLayout.Container>
    </ValueContext.Provider>
  )
})

export default Project
