const defaultType = 'localStorage'
const availableTypes = [defaultType, 'sessionStorage'] as const

type StorageType = (typeof availableTypes)[number]

function WebStorage(storageType: StorageType = defaultType) {
  if (!availableTypes.includes(storageType)) {
    throw Error('Expected localStorage or sessionStorage')
  }

  const storage = window[storageType]

  const read = (key: string, defaultValue?: any) => {
    try {
      const value = storage.getItem(key)
      const isString = typeof value === 'string'

      return isString ? JSON.parse(value) : defaultValue
    } catch (ex) {
      console.log('Ошибка чтения из хранилища', ex)
      return defaultValue
    }
  }

  const write = (key: string, value: any) => {
    try {
      const str = JSON.stringify(value)
      storage.setItem(key, str)
    } catch (ex) {
      console.log('Ошибка записи в хранилище', ex)
    }
  }

  const remove = (key: string) => {
    storage.removeItem(key)
  }

  const clear = () => {
    storage.clear()
  }

  const has = (key: string) => {
    return storage.getItem(key) !== null
  }

  const count = () => {
    return storage.length
  }

  return {
    write,
    read,
    remove,
    clear,
    has,
    count,
  }
}

export default WebStorage
