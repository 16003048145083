import { useTranslation } from 'react-i18next'

import Icon from './message-write-outline.svg?react'

const FeedbackButton = () => {
  const { t } = useTranslation()

  const onClick = () => (window.location.href = 'https://t.me/squad2_Support')

  return (
    <button className="feedback-button" onClick={onClick}>
      <Icon />

      <div className="content">{t('Поделиться обратной связью')}</div>
    </button>
  )
}

export default FeedbackButton
