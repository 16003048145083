import { Checkbox } from '@telegram-apps/telegram-ui'

import { RepeatItem } from 'consts'

interface Props {
  days: RepeatItem[]
  onSelect: (id: string, checked: boolean) => void
  selected: string[]
}

export const WeekDays = ({ days, selected, onSelect }: Props) => {
  return (
    <div className="week-days">
      {days.map(({ id, name }) => {
        const isSelected = selected?.includes(id)

        return (
          <div className="week-day" key={id} onClick={() => onSelect(id, !isSelected)}>
            <div className="name">{name}</div>
            <Checkbox className="checkbox" checked={isSelected} onChange={() => {}} />
          </div>
        )
      })}
    </div>
  )
}
