import { Nullable } from 'types'
import { notification } from 'services'
import { translationContext } from 'contexts'

export const buttonsList = [
  'undo',
  'redo',
  'bold',
  'italic',
  'underline',
  'strikethrough',
  'ul',
  'ol',
  'link',
  'image',
  'eraser',
  'font',
]

export const maxImageSizeInMb = 1
export const maxImageSize = maxImageSizeInMb * 1024 * 1024
export const protocol = 'https://'

export const onInput = (event: any) => {
  const value = event.target.value
  const splitValue = value.split('://')

  if (splitValue.length === 1) {
    event.target.value = protocol
    return
  }

  const cleanedValue = splitValue[splitValue.length - 1]

  event.target.value = (protocol + cleanedValue).replace(/\s+/g, '')
}

export const config = {
  buttons: buttonsList,
  buttonsMD: buttonsList,
  buttonsSM: buttonsList,
  buttonsXS: buttonsList,
  statusBar: true,
  hidePoweredByJodit: true,
  askBeforePasteHTML: false,
  askBeforePasteFromWord: false,
  processPasteFromWord: true,
  addNewLine: false,

  // Отключаем типы ul и ol (list-style-type) в выпадающем списке
  controls: {
    ul: {
      list: undefined,
    },
    ol: {
      list: undefined,
    },
  },
  link: {
    noFollowCheckbox: false,
    openInNewTabCheckbox: false,
    selectMultipleClassName: false,
  },
  cleanHTML: {
    enable: true,
    replaceNBSP: true,
    removeEmptyElements: true,
    cleanOnPaste: true,
    denyTags: 'script',
  },
  events: {
    afterInit: () => {
      // Ставим фокус и маску в поле ввода url при открытии попапа со ссылкой
      const linkButton = document.querySelectorAll('.jodit-toolbar-button[data-ref="link"]')
      let urlInput: Nullable<HTMLInputElement>

      if (linkButton) {
        const onClick = (e: MouseEvent) => {
          if ((e.target as HTMLElement).closest('[data-ref="link"]')) {
            urlInput = document.querySelector('input[data-ref="url_input"]') as HTMLInputElement

            if (urlInput) {
              urlInput?.focus()

              const inputValue = urlInput?.value

              if (!inputValue.startsWith(protocol)) {
                urlInput.value = protocol
              }

              urlInput.addEventListener('input', onInput)
            }
          }
        }

        document.body.addEventListener('click', onClick)

        return () => {
          document.body.removeEventListener('click', onClick)
          urlInput?.removeEventListener('input', onInput)
        }
      }
    },
    paste: (event: any) => {
      const items = (event.clipboardData || event.originalEvent.clipboardData).items
      for (const item of items) {
        if (item.type.indexOf('image') !== -1) {
          const file = item.getAsFile()

          if (file && file.size > maxImageSize) {
            notification.error(
              `${translationContext.t('Размер изображения превышает')} ${maxImageSizeInMb} MB`,
            )
            event.preventDefault()
            return
          }
        }
      }
    },
  },
  insertMode: 'insert_as_text',
  defaultActionOnPasteFromWord: 'insert_as_text',
  limitChars: 3000,
  minHeight: 250,
  maxHeight: 500,
}
