import { observer } from 'mobx-react-lite'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { BackButton } from '@twa-dev/sdk/react'

import { PageHeader, Select } from 'components'
import { useValueContext } from 'contexts'
import { timeZones } from 'utils'

export const ProjectSettings = observer(() => {
  const { projectId } = useParams()
  const navigate = useNavigate()
  const { config } = useValueContext()
  const { t } = useTranslation()

  return (
    <>
      <BackButton />
      <PageHeader> {t('Настройки')}</PageHeader>
      <Select
        value={timeZones[config.timeZoneOffset]}
        label={t('Часовой пояс')}
        onClick={() => navigate(`/projects/${projectId}/settings/timezone`)}
      />
    </>
  )
})
