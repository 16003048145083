import WebStorage from './WebStorage'

const storage = WebStorage()
const settingName = 'userSettings'

// API для работы с настройками пользователей
const UserSettingsApi = {
  // Все настройки
  getAll: () => storage.read(settingName, {}),
  clearAll: () => {
    storage.write(settingName, {})
  },
  removeFromStorage: () => {
    storage.remove(settingName)
  },

  // Настройки пользователя
  getById: (userId: string) => {
    const userSettings = UserSettingsApi.getAll()

    return userSettings[userId] ?? {}
  },
  saveById: (userId: string, allSettings: any) => {
    const userSettings = UserSettingsApi.getAll()
    userSettings[userId] = allSettings

    storage.write(settingName, userSettings)
  },
  clearById: (userId: string) => {
    UserSettingsApi.saveById(userId, {})
  },
  removeById: (userId: string) => {
    const userSettings = UserSettingsApi.getAll()

    delete userSettings[userId]

    storage.write(settingName, userSettings)
  },
}

export default UserSettingsApi
