import get from 'lodash.get'
import set from 'lodash.set'

import UserSettingsApi from './UserSettingsApi'

/**
 * Класс для управления настройками конкретного пользователя.
 *
 * @example
 * localStorage
 * {
 *   "userSettings": {
 *     "user_id": {
 *       "lists": {
 *         "list_1": {
 *           "columns": [],
 *           "filters": {},
 *           "refresh_time": 5000,
 *           "notifications": false
 *         },
 *         "list_2": {
 *           "columns": [],
 *           "filters": {},
 *           "refresh_time": 5000,
 *           "notifications": false
 *         }
 *       }
 *     }
 *   },
 *   "appSettings": {}
 * }
 *
 * // Создаем менеджер, привязанный к конкретному пользователю
 * const settingsManager = new UserSettingsManager(user_id)
 *
 * // Извлекаем в данных пользователя настройки любой вложенности
 * const columnWidth = settingsManager.getByPath('lists.list_1.columns.0.width')
 */
export default class UserSettingsManager {
  userId

  constructor(userId: string) {
    this.userId = userId
  }

  getByPath = (path: string, defaultValue?: any) => {
    const userSettings = UserSettingsApi.getById(this.userId)

    return get(userSettings, path, defaultValue)
  }

  saveByPath = (path: string, value: any) => {
    const userSettings = UserSettingsApi.getById(this.userId)

    set(userSettings, path, value)

    UserSettingsApi.saveById(this.userId, userSettings)
  }
}
