import { ChangeEvent, useEffect, useRef } from 'react'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import debounce from 'lodash.debounce'
import { Input } from '@telegram-apps/telegram-ui'

import './styles.scss'
import { CloseCircleIcon, SearchIcon } from '../UI'

interface Props {
  value: string
  onChange: (value: string) => void
  onSearch: () => void
  className?: string
}

export const SearchInput = (props: Props) => {
  const { value, onChange, onSearch, className } = props
  const { t } = useTranslation()
  const onDebouncedSearch = useRef(debounce(onSearch, 700)).current

  const onReset = () => {
    onChange('')
    onSearch()
  }

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value)
    onDebouncedSearch()
  }

  useEffect(() => {
    return () => {
      onDebouncedSearch.cancel()
    }
  }, [onDebouncedSearch])

  return (
    <div className={cn('search-input-wrapper', className)}>
      <Input
        className="search-input"
        placeholder={`${t('Поиск')}...`}
        before={<SearchIcon fill="var(--gray-medium-tertiary)" />}
        after={value && <CloseCircleIcon className="search-clear-icon" onClick={onReset} />}
        value={value || ''}
        onChange={onChangeHandler}
      />
    </div>
  )
}
