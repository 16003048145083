import { makeAutoObservable } from 'mobx'
import { AxiosResponse } from 'axios'
import WebApp from '@twa-dev/sdk'

import { ProjectApi } from 'api'
import { notification } from 'services'
import { PaginationStore, SortingStore } from 'stores/shared'
import { isDef, isDefAndNotEmpty, uniqBy, UserSettingsManager } from 'utils'
import { translationContext } from 'contexts'
import {
  BasicGetParams,
  IProject,
  IProjectConfig,
  IProjectIssue,
  ITaskPerson,
  NString,
  ProjectConfigResponse,
  ProjectIssuesResponse,
  ProjectResponse,
  ProjectUsersResponse,
  SelectedFilters,
} from 'types'
import { NavigateFunction } from 'react-router'

const initialSelectedFilters = {
  assignees: [],
  statuses: [],
  owners: [],
}

const defaultConfig = {
  statuses: [],
  types: [],
  priorities: [],
  timeZoneOffset: '',
}

export default class ProjectStore {
  project: IProject
  issues: IProjectIssue[] = []
  selectedFilters: SelectedFilters = initialSelectedFilters
  loading = true
  issuesLoading = false
  issuesLoaded = false
  loadingMore = false
  disabled = false
  search: NString = null
  settingsKey = ''
  projectId
  sorting
  pagination
  settingsManager
  navigate: NavigateFunction
  // Справочные значения
  config: IProjectConfig = defaultConfig
  users: ITaskPerson[] = []

  constructor(projectId: string, navigate: NavigateFunction) {
    // TODO: Инициализация пустого проекта?
    // @ts-expect-error Сделать функцию инициализации пустого проекта на основе IProject
    this.project = {}
    this.projectId = Number(projectId)
    this.settingsKey = `project-${this.projectId}`
    this.sorting = new SortingStore()
    this.pagination = new PaginationStore({})
    this.navigate = navigate

    const userId = WebApp?.initDataUnsafe?.user?.id?.toString() || ''
    this.settingsManager = new UserSettingsManager(userId)

    this.applySavedFilters()

    makeAutoObservable(this, undefined, { autoBind: true })
  }

  get hasFilters() {
    return (
      isDef(this.search) ||
      isDef(this.sorting.getSort()) ||
      Object.values(this.selectedFilters).some(isDefAndNotEmpty)
    )
  }

  get isEmptyConfig() {
    return Object.keys(this.config).every(
      (it) => !isDefAndNotEmpty(this.config[it as keyof IProjectConfig]),
    )
  }

  get isEmptyUsers() {
    return !isDefAndNotEmpty(this.users)
  }

  get enabledUsers() {
    return this.users.filter((user) => !!user.enabled)
  }

  setSearch = (value: string) => {
    this.search = value
  }

  resetFilters = () => {
    this.selectedFilters = initialSelectedFilters
    this.sorting.resetSort()
  }

  applySavedFilters = () => {
    const savedFilters = this.settingsManager.getByPath(`${this.settingsKey}.filters`)

    if (savedFilters) {
      this.selectedFilters = savedFilters
    }

    const savedSorting = this.settingsManager.getByPath(`${this.settingsKey}.sorting`)

    if (savedSorting) {
      this.sorting.setSort(savedSorting)
    }
  }

  saveFilters = () => {
    this.settingsManager.saveByPath(`${this.settingsKey}.filters`, this.selectedFilters)
    this.settingsManager.saveByPath(`${this.settingsKey}.sorting`, {
      key: this.sorting.key,
      direction: this.sorting.direction,
    })
  }

  setArrayFilter = (field: keyof SelectedFilters, value: string | number) => {
    if (!Object.prototype.hasOwnProperty.call(this.selectedFilters, field)) {
      return
    }

    const selectedValue = this.selectedFilters[field].find((it) => it === value)

    if (!isDef(selectedValue)) {
      this.selectedFilters[field] = [...this.selectedFilters[field], value]
    } else {
      this.selectedFilters[field] = this.selectedFilters[field].filter((it) => it !== value)
    }
  }

  clearArrayFilter = (field: keyof SelectedFilters) => {
    if (!Object.prototype.hasOwnProperty.call(this.selectedFilters, field)) {
      return
    }
    this.selectedFilters[field] = []
  }

  getListParams = (): BasicGetParams => {
    return {
      Page: this.pagination.currentPage,
      Size: this.pagination.pageSize,
      Sorts: this.sorting.getSort(),
      Title: isDefAndNotEmpty(this.search) ? this.search : undefined,
      ...this.selectedFilters,
    }
  };

  *initDictsIfEmpty() {
    const promises = []

    if (this.isEmptyConfig) {
      promises.push(this.loadConfig(this.projectId))
    }
    if (this.isEmptyUsers) {
      promises.push(this.loadUsers(this.projectId))
    }

    yield Promise.allSettled(promises)
  }

  *loadById(projectId: number) {
    if (Number.isFinite(projectId)) {
      try {
        this.loading = true
        const response: AxiosResponse<ProjectResponse> = yield ProjectApi.getById(projectId)
        this.project = response.data.data
      } catch (ex: any) {
        if (ex?.status === 404) {
          notification.error(
            `${translationContext.t('Проект не найден')}. ${translationContext.t('Пожалуйста, удалите и снова добавьте бота в группу')}`,
            {
              autoClose: 4000,
            },
          )
        } else {
          notification.error('Ошибка загрузки проекта')
        }
        this.navigate('/projects', { replace: true })
        this.disabled = true
      } finally {
        this.loading = false
      }
    }
  }

  *loadIssues(projectId: number) {
    if (Number.isFinite(projectId)) {
      this.issuesLoading = true
      this.issuesLoaded = false

      try {
        const response: AxiosResponse<ProjectIssuesResponse> = yield ProjectApi.getIssuesById(
          projectId,
          this.getListParams(),
        )

        const { data, currentPage, totalPages } = response.data

        this.issues = data

        this.pagination.setCurrentPage(currentPage)
        this.pagination.totalPages = totalPages
      } catch (ex: any) {
        notification.error('Ошибка загрузки задач')
        console.log(ex?.message)
      } finally {
        this.issuesLoading = false
        this.issuesLoaded = true
      }
    }
  }

  *loadMoreIssues() {
    const isEndOfData = this.pagination.currentPage > this.pagination.totalPages

    if (Number.isFinite(this.projectId) && !isEndOfData && !this.loadingMore) {
      this.loadingMore = true

      try {
        const response: AxiosResponse<ProjectIssuesResponse> = yield ProjectApi.getIssuesById(
          this.projectId,
          this.getListParams(),
        )

        const { data, currentPage } = response.data

        this.issues = uniqBy('id', [...this.issues, ...data])
        this.pagination.setCurrentPage(currentPage)
      } catch (ex: any) {
        notification.error('Ошибка загрузки задач')
        console.log(ex?.message)
      } finally {
        this.loadingMore = false
      }
    }
  }

  *loadConfig(projectId: number) {
    if (Number.isFinite(projectId)) {
      try {
        const response: AxiosResponse<ProjectConfigResponse> =
          yield ProjectApi.getConfigById(projectId)

        this.config = response.data.data || defaultConfig
      } catch (ex: any) {
        console.log(ex?.message)
      }
    }
  }

  *loadUsers(projectId: number) {
    if (Number.isFinite(projectId)) {
      try {
        const response: AxiosResponse<ProjectUsersResponse> =
          yield ProjectApi.getUsersById(projectId)

        this.users = response.data.data || []
      } catch (ex: any) {
        console.log(ex?.message)
      }
    }
  }

  *saveConfig() {
    if (Number.isFinite(this.projectId)) {
      this.loading = true

      try {
        yield ProjectApi.saveConfig(this.projectId, this.config)

        notification.success('Изменения сохранены')
      } catch (ex: any) {
        notification.error('Не удалось сохранить изменения')
        console.log(ex?.message)
      } finally {
        this.loading = false
      }
    }
  }

  setTimezone = (value: string) => {
    this.config.timeZoneOffset = value
  }
}
