import { translationContext } from 'contexts'

import './styles.scss'

interface Props {
  title: string
  message?: string
}

export const Notification = (props: Props) => {
  const { title, message } = props

  return (
    <div className="notification">
      <div>{translationContext.t(title)}</div>
      {message && <div className="message">{translationContext.t(message)}</div>}
    </div>
  )
}
