import React, { memo, useState, useEffect, ReactNode } from 'react'
import cn from 'classnames'

import { ArrowIcon } from 'components'
import { Nullable } from 'types'

import './styles.scss'
import {
  dayNamesRus,
  monthNamesRus,
  isValidDate,
  getValidDate,
  getDaysInMonth,
  getFirstDayOfMonth,
} from './utils'
import { toZonedTime } from 'date-fns-tz'

const getPreviousMonthDays = (month: number, year: number, firstDay: number): ReactNode[] => {
  const days = []

  const prevMonth = month === 0 ? 11 : month - 1
  const prevYear = month === 0 ? year - 1 : year
  const prevMonthDays = getDaysInMonth(prevMonth, prevYear)

  for (let i = 0; i < firstDay; i++) {
    const day = prevMonthDays - firstDay + i + 1
    days.push(
      <div key={`prev-${day}`} className="day empty">
        {day}
      </div>,
    )
  }

  return days
}

interface ICurrentMonthDays {
  year: number
  month: number
  daysInMonth: number
  currentDate: Date
  onDateClick: (day: number, month: number, year: number) => void
  timeZone: string
  disabled?: boolean
  disablePast?: boolean
}

const getCurrentMonthDays = ({
  year,
  month,
  timeZone,
  disabled,
  daysInMonth,
  currentDate,
  onDateClick,
  disablePast,
}: ICurrentMonthDays): ReactNode[] => {
  const currentDays = []
  const today = toZonedTime(new Date(), timeZone)
  today.setHours(0, 0, 0, 0)

  for (let i = 1; i <= daysInMonth; i++) {
    const date = new Date(year, month, i)
    const isSelected =
      isValidDate(currentDate) && date.toDateString() === currentDate.toDateString()

    const isToday = date.toDateString() === today.toDateString()
    const isPastDate = date < today

    const isDisabled = disabled || (disablePast && isPastDate)

    currentDays.push(
      <div
        key={`day-${i}`}
        className={cn('day', {
          disabled: isDisabled,
          selected: isSelected,
          today: isToday,
        } as any)}
        onClick={isDisabled ? undefined : () => onDateClick(i, month, year)}
      >
        {i}
      </div>,
    )
  }

  return currentDays
}

const getNextMonthDays = (daysInMonth: number, firstDay: number): ReactNode[] => {
  const days = []
  const nextMonthDays = 42 - (firstDay + daysInMonth) // 42 - общее кол-во ячеек в гриде календаря

  for (let i = 1; i <= nextMonthDays; i++) {
    days.push(
      <div key={`next-${i}`} className="day empty">
        {i}
      </div>,
    )
  }

  return days
}

interface IRenderCalendar {
  currentDate: Date
  onDateClick: (day: number, month: number, year: number) => void
  timeZone: string
  disabled?: boolean
  disablePast?: boolean
}

const renderCalendar = ({
  currentDate,
  onDateClick,
  disabled,
  disablePast,
  timeZone,
}: IRenderCalendar): React.ReactNode[] => {
  const month = currentDate.getMonth()
  const year = currentDate.getFullYear()
  const daysInMonth = getDaysInMonth(month, year)
  const firstDay = getFirstDayOfMonth(month, year)

  const calendarDays = []

  calendarDays.push(...getPreviousMonthDays(month, year, firstDay))
  calendarDays.push(
    ...getCurrentMonthDays({
      month,
      year,
      daysInMonth,
      timeZone,
      disabled,
      disablePast,
      currentDate,
      onDateClick,
    }),
  )
  calendarDays.push(...getNextMonthDays(daysInMonth, firstDay))

  return calendarDays
}

interface Props {
  onChange: (d: Date) => void
  value?: Nullable<Date>
  timeZone?: string
  disabled?: boolean
  disablePast?: boolean
  className?: string
  monthNames?: string[] // Можно передать переведенные на другой язык значения
  dayNames?: string[] // Можно передать переведенные на другой язык значения
}

export const Calendar = memo((props: Props) => {
  const {
    value,
    className,
    timeZone = '',
    monthNames = monthNamesRus,
    dayNames = dayNamesRus,
    disabled,
    disablePast,
    onChange,
  } = props
  const initialDate = getValidDate(null, timeZone)
  const [currentDate, setCurrentDate] = useState<Date>(initialDate)

  useEffect(() => {
    setCurrentDate(getValidDate(value, timeZone))
  }, [timeZone, value])

  const onPrevMonth = () => {
    let newMonth = currentDate.getMonth() - 1
    let newYear = currentDate.getFullYear()

    if (newMonth < 0) {
      newMonth = 11
      newYear -= 1
    }

    setCurrentDate(new Date(newYear, newMonth, 1))
  }

  const onNextMonth = () => {
    let newMonth = currentDate.getMonth() + 1
    let newYear = currentDate.getFullYear()

    if (newMonth > 11) {
      newMonth = 0
      newYear += 1
    }

    setCurrentDate(new Date(newYear, newMonth, 1))
  }

  const onDateClick = (day: number, month: number, year: number) => {
    if (disabled) {
      return
    }

    const newDate = new Date(year, month, day)
    onChange(newDate)
  }

  return (
    <div className={cn('calendar', className)}>
      <div className="header">
        <button className="navButton" onClick={onPrevMonth} disabled={disabled}>
          <ArrowIcon />
        </button>
        <div>
          {monthNames[currentDate.getMonth()]} {currentDate.getFullYear()}
        </div>
        <button className="navButton buttonNext" onClick={onNextMonth} disabled={disabled}>
          <ArrowIcon />
        </button>
      </div>
      <div className="days">
        {dayNames.map((day) => (
          <div key={day} className="dayName">
            {day}
          </div>
        ))}
      </div>
      <div className="grid">
        {renderCalendar({ currentDate, onDateClick, disabled, disablePast, timeZone })}
      </div>
    </div>
  )
})
