import { makeAutoObservable } from 'mobx'
import { AxiosResponse } from 'axios'

import { ProjectApi } from 'api'
import { notification } from 'services'
import type { BasicGetParams, ProjectsResponse, IProjectItem } from 'types'
import { PaginationStore } from 'stores'
import { uniqBy } from 'utils'

export default class ProjectsStore {
  items: IProjectItem[]
  pagination: PaginationStore
  loading = true
  loadingMore = false

  constructor() {
    this.items = []
    this.pagination = new PaginationStore({})

    makeAutoObservable(this, undefined, { autoBind: true })
  }

  getListParams = (): BasicGetParams => {
    return {
      Size: this.pagination.pageSize,
      Page: this.pagination.currentPage,
    }
  };

  *loadMoreProjects() {
    try {
      if (this.loadingMore) {
        return
      }

      this.loadingMore = true
      const response: AxiosResponse<ProjectsResponse> = yield ProjectApi.getAll(
        this.getListParams(),
      )

      const { data, currentPage } = response.data
      this.items = uniqBy('id', [...this.items, ...data])
      this.pagination.setCurrentPage(currentPage)
    } catch (ex: any) {
      console.log(ex?.message)
      notification.error('Ошибка загрузки проектов')
    } finally {
      this.loadingMore = false
    }
  }

  *loadAll() {
    this.loading = true

    try {
      const response: AxiosResponse<ProjectsResponse> = yield ProjectApi.getAll(
        this.getListParams(),
      )
      const { totalPages, currentPage, data } = response.data

      this.pagination.totalPages = totalPages
      this.pagination.setCurrentPage(currentPage)
      this.items = data
    } catch (ex: any) {
      console.log(ex?.message)
      notification.error('Ошибка загрузки проектов')
    } finally {
      this.loading = false
    }
  }
}
