import { format, setHours, setMinutes } from 'date-fns'

import { IEditedTask } from 'types'
import { fromZoned, isTimeValid, toZoned } from 'utils'
import { selectedDaysId } from 'consts'

type EmptyTaskParams = {
  type?: Record<string, any>
  status?: Record<string, any>
  priority?: Record<string, any>
  owner?: Record<string, any>
  timeZoneOffset?: string
}

export const createEmptyTask = (params?: EmptyTaskParams): Record<string, any> => {
  const { type, status, priority, owner, timeZoneOffset = '' } = params ?? {}

  const date = toZoned(new Date(), timeZoneOffset)
  const time = format(date, 'HH:mm')

  return {
    title: '',
    description: '',
    notification: {
      start: '',
      repeat: {
        type: 'Never',
        repeatDays: [],
      },
    },
    notificationDate: date,
    notificationTime: time,
    type,
    status,
    priority,
    owner,
    assignedTo: owner,
    tags: [],
  }
}

const serializeNotificationTime = ({
  date,
  time,
  timeZone,
}: {
  date: Date
  time: string
  timeZone: string
}): string => {
  if (!date) return ''

  const validTime = isTimeValid(time) ? time : '00:00'
  const [hours, minutes] = validTime.split(':').map(Number)

  const withTime = setHours(setMinutes(date, minutes), hours)
  const fromZonedTime = fromZoned(withTime, timeZone)
  return fromZonedTime.toISOString()
}

export const serializeTask = (task: any, timeZone: string): IEditedTask => {
  if (!task) return {} as IEditedTask

  const {
    id,
    title,
    description,
    type,
    status,
    priority,
    assignedTo,
    owner,
    tags,
    notification,
    notificationDate,
    notificationTime,
  } = task

  const repeatType = notification?.repeat?.type
  const repeat: Record<string, any> = {
    type: repeatType,
  }

  if (repeatType === selectedDaysId) {
    repeat.repeatDays = notification?.repeat?.repeatDays
  }

  return {
    id,
    tags,
    title,
    description,
    typeId: type?.id,
    priorityId: priority?.id,
    statusId: status?.id,
    assignedToId: assignedTo?.id,
    reporterToId: owner?.id,
    notification: {
      start: serializeNotificationTime({
        date: notificationDate,
        time: notificationTime,
        timeZone,
      }),
      repeat,
    },
  }
}

export const parseTask = (task: any, timeZone: string): IEditedTask => {
  const notificationStart = task?.notification?.start || new Date().toISOString()
  const date = toZoned(new Date(notificationStart), timeZone)
  const time = format(date, 'HH:mm')

  return {
    ...task,
    notification: {
      ...task?.notification,
      repeat: {
        type: task?.notification?.repeat?.type || 'Never',
        repeatDays: task?.notification?.repeatDays || [],
      },
    },
    notificationDate: date,
    notificationTime: time,
  }
}
