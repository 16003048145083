import { isTimeValid } from 'utils'

export const isFormValid = (task: any) => {
  if (!task) return false

  const hasTitle = task.title.trim().length >= 5
  const isNotificationTimeValid = isTimeValid(task.notificationTime)

  return (
    hasTitle &&
    isNotificationTimeValid &&
    [task.status, task.priority, task.type, task.notificationDate].every((value) => value != null)
  )
}
