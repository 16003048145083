import { useMemo } from 'react'
import JoditEditor from 'jodit-react'
import { useTranslation } from 'react-i18next'

import './styles.scss'
import { config } from './utils'

interface Props {
  value: string
  onChange: (value: string) => void
}

export const Editor = (props: Props) => {
  const { value, onChange } = props
  const { i18n } = useTranslation()
  const editorConfig = useMemo(() => {
    return {
      ...config,
      language: i18n.language,
    }
  }, [i18n.language])

  return (
    <div className="editor-wrapper">
      <JoditEditor config={editorConfig} value={value} onChange={onChange} />
    </div>
  )
}
