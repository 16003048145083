import { useTranslation } from 'react-i18next'

import { Chip } from 'components'
import type { IProjectItemIssue } from 'types'
import { defaultColor } from 'consts'
import { useColor } from 'utils'

const IssueList = ({ items }: { items: IProjectItemIssue[] }) => {
  const { t } = useTranslation()
  const color = useColor()

  return (
    <ul className="issue-list">
      {items.map((issue, index) => (
        <li key={index} className="issue-item">
          <Chip background={issue[color] || issue[defaultColor]} className="issue-item-chip">
            {issue.count}
          </Chip>
          <div className="label">{t(issue.status)}</div>
        </li>
      ))}
    </ul>
  )
}

export default IssueList
