import React from 'react'
import cn from 'classnames'

import { ArrowIcon, Button } from 'components'
import { ButtonProps } from 'components/UI/Button'

import './styles.scss'

interface Props extends Omit<ButtonProps, 'label'> {
  value: string
  label?: React.ReactNode
  className?: string
}

export const Select = (props: Props) => {
  const { label, value, className, ...rest } = props

  return (
    <div className={cn('select-option', className)}>
      {label && <span className="select-label">{label}</span>}
      <Button
        align="between"
        className="select-button"
        after={<ArrowIcon className="select-button-arrow" />}
        {...rest}
      >
        {value}
      </Button>
    </div>
  )
}
