import { useContext } from 'react'

import { AppContext } from 'contexts'

const useApp = (): any => {
  const context = useContext(AppContext)

  if (context === undefined) {
    throw new Error('useApp must be used within a AppProvider')
  }

  return context
}

export default useApp
