export interface DictValue {
  id?: number
  key?: string
  title: string
  colorHexLight: string
  colorHexDark: string
  iconUrl: string
}

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
}
