import { Nullable } from 'types'
import { toZonedTime } from 'date-fns-tz'

export const isValidDate = (date: any): boolean => date instanceof Date && !isNaN(date as any)

export const getValidDate = (value: Nullable<Date>, timeZone: string): Date =>
  isValidDate(value) ? (value as Date) : toZonedTime(new Date(), timeZone)

export const getDaysInMonth = (month: number, year: number): number =>
  new Date(year, month + 1, 0).getDate()

export const getFirstDayOfMonth = (month: number, year: number): number => {
  const day = new Date(year, month, 1).getDay()
  return day === 0 ? 6 : day - 1
}

export const monthNamesRus = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
]

export const dayNamesRus = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс']
