import { apiService } from 'services'

import {
  ProjectsResponse,
  ProjectResponse,
  INewProject,
  ProjectIssuesResponse,
  BasicGetParams,
  ProjectConfigResponse,
  ProjectUsersResponse,
  IProjectConfig,
} from 'types'

export const PROJECTS_URL = '/projects'

const getAll = async (params: BasicGetParams) =>
  apiService.get<ProjectsResponse>(PROJECTS_URL, { params })

const getById = async (projectId: number) => {
  return apiService.get<ProjectResponse>(`${PROJECTS_URL}/${projectId}`)
}

const getIssuesById = async (projectId: number, params: BasicGetParams) => {
  return apiService.get<ProjectIssuesResponse>(`${PROJECTS_URL}/${projectId}/issues`, { params })
}

const getConfigById = async (projectId: number | string) => {
  return apiService.get<ProjectConfigResponse>(`${PROJECTS_URL}/${projectId}/config`)
}

const getUsersById = async (projectId: number | string) => {
  return apiService.get<ProjectUsersResponse>(`${PROJECTS_URL}/${projectId}/users`)
}

const create = async (newProject: INewProject) => {
  return apiService.post<ProjectResponse>(PROJECTS_URL, newProject)
}

const saveConfig = async (projectId: number, config: IProjectConfig) => {
  return apiService.put<ProjectConfigResponse>(`${PROJECTS_URL}/${projectId}/config`, config)
}

const ProjectApi = {
  getAll,
  getById,
  getIssuesById,
  getConfigById,
  getUsersById,
  saveConfig,
  create,
}

export default ProjectApi
