import cn from 'classnames'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { BackButton } from '@twa-dev/sdk/react'

import { Button, FlexLayout, PageHeader, PageSpinner } from 'components'
import { useValueContext } from 'contexts'
import { timeZones } from 'utils'

import './styles.scss'

export const TimeZone = observer(() => {
  const { t } = useTranslation()
  const { setTimezone, saveConfig, config, loading } = useValueContext()
  const { timeZoneOffset } = config

  return (
    <FlexLayout.Container vertical>
      <BackButton />
      <PageSpinner open={loading} />
      <PageHeader>{t('Часовой пояс')}</PageHeader>
      <FlexLayout.Content scrollable>
        {Object.keys(timeZones).map((it) => (
          <div
            key={it}
            className={cn('time-zone', { selected: timeZoneOffset === it })}
            onClick={() => setTimezone(it)}
          >
            {timeZones[it]}
          </div>
        ))}
      </FlexLayout.Content>
      <div className="operations-buttons">
        <Button className="operation-button save" disabled={!timeZoneOffset} onClick={saveConfig}>
          {t('Сохранить изменения')}
        </Button>
      </div>
    </FlexLayout.Container>
  )
})
