import { useTranslation } from 'react-i18next'

import { Asterisk, PriorityIcon, Select } from 'components'
import { defaultColor } from 'consts'
import { DictValue } from 'types'
import { useColor } from 'utils'

import './styles.scss'

interface Props {
  color: string
  value: DictValue
  onClick: () => void
}

export const PrioritySelect = (props: Props) => {
  const { value, onClick } = props
  const { t } = useTranslation()
  const color = useColor()
  const iconColor = value?.[color] || value?.[defaultColor]

  return (
    <Select
      className="priority-select"
      label={
        <>
          {t('Приоритет')} <Asterisk />
        </>
      }
      value={t(value?.title)}
      before={value?.key && <PriorityIcon width={14} height={14} fill={iconColor} />}
      onClick={onClick}
    />
  )
}
