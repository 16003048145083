import React from 'react'
import cn from 'classnames'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'

import { FlexLayout, PageHeader, OptionCard } from 'components'
import { useTranslateConsts } from 'utils'
import { selectedDaysId } from 'consts'
import { useValueContext } from 'contexts'

import { WeekDays } from './WeekDays'
import './styles.scss'

export const Repeat = observer(() => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { repeatTimeItems, repeatDaysItems } = useTranslateConsts()
  const { taskStore } = useValueContext()
  const { task, setTaskField } = taskStore

  const repeatType = task?.notification?.repeat?.type

  const onSelectDay = (day: string, selected: boolean) => {
    const days = task?.notification?.repeat?.repeatDays
    const newDays = selected ? [...days, day] : days.filter((it: string) => it !== day)
    setTaskField('notification.repeat.repeatDays', newDays)
  }

  const onSelectType = (id: string) => {
    setTaskField('notification.repeat.type', id)

    if (id !== selectedDaysId) {
      navigate(-1)
    }
  }

  return (
    <FlexLayout.Container vertical>
      <PageHeader>{t('Повторение')}</PageHeader>
      <FlexLayout.Content scrollable>
        <div className="button-container">
          {repeatTimeItems.map((it) => {
            const isSelectedDaysItem = it.id === selectedDaysId

            return (
              <React.Fragment key={it.id}>
                <OptionCard
                  className={cn('button-repeat-type', {
                    active: repeatType === it.id,
                  })}
                  onClick={() => onSelectType(it.id)}
                >
                  {it.name}
                </OptionCard>
                {isSelectedDaysItem && repeatType === selectedDaysId && (
                  <WeekDays
                    days={repeatDaysItems}
                    selected={task?.notification?.repeat?.repeatDays}
                    onSelect={(value: string, selected: boolean) => onSelectDay(value, selected)}
                  />
                )}
              </React.Fragment>
            )
          })}
        </div>
      </FlexLayout.Content>
    </FlexLayout.Container>
  )
})
