import React from 'react'
import { useTranslation } from 'react-i18next'

import { BottomFooter, CreateButton } from 'components'
import { botName } from 'consts'

import './styles.scss'

const Footer = () => {
  const { t } = useTranslation()

  const onClick = (e: React.MouseEvent) => {
    e.preventDefault()
    window.location.href = `https://t.me/${botName}?startgroup=setup&admin=pin_messages+promote_members`
  }

  return (
    <BottomFooter className="home-bottom-footer">
      <CreateButton to="" onClick={onClick}>
        {t('Создать проект')}
      </CreateButton>
    </BottomFooter>
  )
}

export default Footer
