export type RepeatItem = {
  id: string
  name: string
}

export const selectedDaysId = 'SelectedDays'

export const repeatTimeItems: RepeatItem[] = [
  { id: 'Never', name: 'Никогда' },
  { id: 'Daily', name: 'Ежедневно' },
  { id: selectedDaysId, name: 'Выбранные дни' },
  { id: 'Weekly', name: 'Еженедельно' },
  { id: 'Monthly', name: 'Ежемесячно' },
  { id: 'Yearly', name: 'Ежегодно' },
]

export const repeatDaysItems: RepeatItem[] = [
  { id: 'mn', name: 'Пн' },
  { id: 'tu', name: 'Вт' },
  { id: 'wd', name: 'Ср' },
  { id: 'th', name: 'Чт' },
  { id: 'fr', name: 'Пт' },
  { id: 'st', name: 'Сб' },
  { id: 'sn', name: 'Вс' },
]
