import { StrictMode, Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import { ToastContainer } from 'react-toastify'
import { RouterProvider } from 'react-router-dom'
import { AppRoot } from '@telegram-apps/telegram-ui'
import '@telegram-apps/telegram-ui/dist/styles.css'
import 'react-toastify/dist/ReactToastify.css'

import { AppProvider } from 'contexts'

import './i18n'
import './assets/styles/index.scss'

import router from './router'

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <AppProvider>
      <AppRoot>
        <Suspense fallback="">
          <RouterProvider router={router} />
          <ToastContainer />
        </Suspense>
      </AppRoot>
    </AppProvider>
  </StrictMode>,
)
