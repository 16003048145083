import { BaseSvgIcon } from './BaseSvgIcon'

export const PriorityIcon = (props: any) => {
  const { width = 9, height = 9, fill = 'currentColor', ...rest } = props

  return (
    <BaseSvgIcon width={width} height={height} fill={fill} viewBox="0 0 9 9" {...rest}>
      <path d="M4.05 0H4.95L9 2.7V9H8.1L4.5 7.2L0.9 9H0V2.7L4.05 0Z" fill={fill} />
    </BaseSvgIcon>
  )
}
