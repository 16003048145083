import { toast, ToastPosition, ToastOptions } from 'react-toastify'

import { translationContext } from 'contexts'
import { Notification } from 'components'

const position: ToastPosition = 'bottom-right'

const defaultToastConfig = {
  position,
  theme: 'dark',
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
}

type Message = {
  title: string
  description?: string
}

const getMessageJsx = (message: string | Message) =>
  typeof message === 'string' ? (
    translationContext.t(message)
  ) : (
    <Notification title={message.title} message={message.description} />
  )

const error = (message: string | Message, options?: ToastOptions) => {
  const messageJsx = getMessageJsx(message)

  toast.error(messageJsx, {
    ...defaultToastConfig,
    ...options,
  })
}

const success = (message: string | Message, options?: ToastOptions) => {
  const messageJsx = getMessageJsx(message)

  toast.success(messageJsx, {
    ...defaultToastConfig,
    ...options,
  })
}

export default { error, success }
