import { useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Input } from '@telegram-apps/telegram-ui'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import Inputmask from 'inputmask'

import { Calendar, FlexLayout, PageHeader, Select } from 'components'
import { getIanaTimeZone, isTimeValid, toZoned, useTranslateConsts } from 'utils'
import { useValueContext } from 'contexts'

import './styles.scss'

export const Reminder = observer(() => {
  const { t } = useTranslation()
  const { monthNames, dayNames, repeatTimeItems } = useTranslateConsts()
  const navigate = useNavigate()
  const { projectId, taskId } = useParams()
  const { projectStore, taskStore } = useValueContext()
  const { task, setTaskField } = taskStore
  const { config } = projectStore

  const ref = useCallback((node: HTMLInputElement) => {
    if (node) {
      Inputmask({ mask: '99:99' }).mask(node)
    }
  }, [])

  const isValidTime = isTimeValid(task?.notificationTime)

  const onClear = () => {
    setTaskField('notificationDate', toZoned(new Date(), projectStore.config.timeZoneOffset))
    setTaskField('notificationTime', '00:00')
  }

  const repeatValue = repeatTimeItems.find((it) => it.id === task?.notification?.repeat?.type)?.name

  return (
    <FlexLayout.Container vertical>
      <PageHeader>{t('Напоминание')}</PageHeader>
      <FlexLayout.Content scrollable>
        <Calendar
          timeZone={getIanaTimeZone(config?.timeZoneOffset)}
          value={task?.notificationDate}
          onChange={(date) => {
            setTaskField('notificationDate', date)
          }}
          monthNames={monthNames}
          dayNames={dayNames}
          className="reminder-calendar"
          disablePast
        />
        <div className="task-input-wrapper select-option reminder-time">
          <span className="select-label">{t('Время')}</span>
          <Input
            ref={ref}
            placeholder="00:00"
            className="task-input"
            value={task?.notificationTime || ''}
            onChange={(e) => setTaskField('notificationTime', e.target.value)}
          />
        </div>
        <Select
          label={t('Повторять')}
          value={repeatValue || ''}
          onClick={() => navigate(`/projects/${projectId}/tasks/${taskId}/settings/repeat`)}
        />
      </FlexLayout.Content>

      <div className="operations-buttons">
        <Button
          className="operation-button save"
          disabled={!isValidTime}
          onClick={() => navigate(-1)}
        >
          {t('Применить')}
        </Button>
        <Button className="operation-button delete" onClick={onClear}>
          {t('Очистить')}
        </Button>
      </div>
    </FlexLayout.Container>
  )
})
