import { Outlet } from 'react-router-dom'

import { FlexLayout } from 'components'

import NavBar from './NavBar'
import { Footer } from './Footer'

import './styles.scss'

const HomeLayout = () => {
  return (
    <FlexLayout.Container vertical>
      <FlexLayout.Content className="home-content">
        <Outlet />
      </FlexLayout.Content>

      <Footer />
      <NavBar />
    </FlexLayout.Container>
  )
}

export default HomeLayout
