import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { FlexLayout, DownloadCircleIcon } from 'components'

import './styles.scss'

export const FilePreview = () => {
  const { state } = useLocation()
  const { t } = useTranslation()

  return (
    <FlexLayout.Container vertical>
      {state?.url && (
        <>
          <a href={state.url} download={state?.name} className="file-download-link">
            <DownloadCircleIcon />
            {t('Скачать')}
          </a>
          {state?.type.startsWith('image/') ? (
            <FlexLayout.Content scrollable>
              <img src={state.url} className="download-img" alt="" />
            </FlexLayout.Content>
          ) : (
            <span className="file-name">{state.name}</span>
          )}
        </>
      )}
    </FlexLayout.Container>
  )
}
