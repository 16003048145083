import { useState, useEffect } from 'react'
import WebApp from '@twa-dev/sdk'

const setHeaderColor = (theme: string) => {
  WebApp.setHeaderColor(theme === 'light' ? '#fff' : '#000')
}

export const useTheme = () => {
  const [theme, setTheme] = useState('light')
  const [prevTheme, setPrevTheme] = useState('')

  useEffect(() => {
    const tgTheme = WebApp.colorScheme

    setTheme(tgTheme)
    setPrevTheme(tgTheme)
    setHeaderColor(tgTheme)
    document.body.classList.add(`app-${tgTheme}`)

    const changeTheme = () => {
      const newTgTheme = WebApp.colorScheme
      setTheme(newTgTheme)
      setHeaderColor(newTgTheme)
      setPrevTheme(newTgTheme)
      document.body.classList.remove(`app-${prevTheme}`)
      document.body.classList.add(`app-${newTgTheme}`)
    }

    WebApp.onEvent('themeChanged', changeTheme)

    return () => {
      WebApp.offEvent('themeChanged', changeTheme)
    }
  }, [prevTheme])

  return theme
}
