import { useEffect, useState } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'

import { ProjectStore } from 'stores'
import { ValueContext } from 'contexts'

export const ProjectLayout = () => {
  const { projectId } = useParams()
  const navigate = useNavigate()

  const [store] = useState(() => new ProjectStore(projectId || '', navigate))
  const { initDictsIfEmpty, loadById } = store

  useEffect(() => {
    const loadDictsAndInit = () => {
      const id = Number(projectId)

      if (id) {
        initDictsIfEmpty()
        loadById(id)
      }
    }

    loadDictsAndInit()
  }, [initDictsIfEmpty, loadById, projectId, store])

  return (
    <ValueContext.Provider value={store}>
      <Outlet />
    </ValueContext.Provider>
  )
}
