import { Link, type To } from 'react-router-dom'

import PlusIcon from './plus.svg?react'

import './styles.scss'

interface CreateButtonProps {
  to: To
  children: React.ReactNode
  onClick?: (e: any) => void
}

const CreateButton = ({ to, onClick, children }: CreateButtonProps) => {
  return (
    <Link to={to} onClick={onClick} className="create-button">
      <PlusIcon /> {children}
    </Link>
  )
}

export default CreateButton
