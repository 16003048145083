import React from 'react'
import { Modal, Placeholder } from '@telegram-apps/telegram-ui'
import { useTranslation } from 'react-i18next'

import { Button } from 'components'

import './styles.scss'

interface Props {
  children: React.ReactNode
  onSetOpen: (value: boolean) => void
  onActionClick: () => void
  open?: boolean
  closeOnConfirm?: boolean
  confirmLabel?: string
  cancelLabel?: string
}

export const ConfirmModal = (props: Props) => {
  const {
    children,
    confirmLabel = 'Удалить',
    cancelLabel = 'Отмена',
    closeOnConfirm = true,
    open,
    onSetOpen,
    onActionClick,
  } = props

  const { t } = useTranslation()

  const onActionClickHandler = () => {
    onActionClick?.()

    if (closeOnConfirm) {
      onSetOpen(false)
    }
  }

  const onClose = () => onSetOpen(false)

  return (
    <Modal
      open={open}
      onOpenChange={(state) => onSetOpen(state)}
      className="modal-rounded"
      dismissible
    >
      <Placeholder>
        <div className="confirm-text">{children}</div>

        <div className="confirm-buttons">
          <Button className="confirm-option cancel-button" onClick={onClose}>
            {t(cancelLabel)}
          </Button>
          <Button className="confirm-option confirm-button" onClick={onActionClickHandler}>
            {t(confirmLabel)}
          </Button>
        </div>
      </Placeholder>
    </Modal>
  )
}
