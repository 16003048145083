export const getCountCase = (count: number, words: string[], withCount = false): string => {
  const value = Math.abs(count) % 100
  const num = value % 10
  let returnValue = words[2]

  if (value > 10 && value < 20) {
    returnValue = words[2]
  }
  if (num > 1 && num < 5) {
    returnValue = words[1]
  }
  if (num === 1) {
    returnValue = words[0]
  }
  return withCount ? `${count} ${returnValue}` : returnValue
}
