import { differenceInDays, format, parseISO } from 'date-fns'
import { fromZonedTime, toZonedTime } from 'date-fns-tz'
import { enUS, es, Locale, ru } from 'date-fns/locale'

const localesMap: Record<string, Locale> = {
  en: enUS,
  es,
  ru,
}

export const timeZones: Record<string, string> = {
  '+12:00': 'UTC-12',
  '+11:00': 'UTC-11',
  '+10:00': 'UTC-10',
  '+09:00': 'UTC-9',
  '+08:00': 'UTC-8',
  '+07:00': 'UTC-7',
  '+06:00': 'UTC-6',
  '+05:00': 'UTC-5',
  '+04:00': 'UTC-4',
  '+03:00': 'UTC-3',
  '+02:00': 'UTC-2',
  '+01:00': 'UTC-1',
  '+00:00': 'UTC+0',
  '-01:00': 'UTC+1',
  '-02:00': 'UTC+2',
  '-03:00': 'UTC+3',
  '-04:00': 'UTC+4',
  '-05:00': 'UTC+5',
  '-06:00': 'UTC+6',
  '-07:00': 'UTC+7',
  '-08:00': 'UTC+8',
  '-09:00': 'UTC+9',
  '-10:00': 'UTC+10',
  '-11:00': 'UTC+11',
  '-12:00': 'UTC+12',
  '-13:00': 'UTC+13',
  '-14:00': 'UTC+14',
}

const ianaTimeZoneMap: Record<string, string> = {
  '+12:00': 'Etc/GMT+12',
  '+11:00': 'Etc/GMT+11',
  '+10:00': 'Etc/GMT+10',
  '+09:00': 'Etc/GMT+9',
  '+08:00': 'Etc/GMT+8',
  '+07:00': 'Etc/GMT+7',
  '+06:00': 'Etc/GMT+6',
  '+05:00': 'Etc/GMT+5',
  '+04:00': 'Etc/GMT+4',
  '+03:00': 'Etc/GMT+3',
  '+02:00': 'Etc/GMT+2',
  '+01:00': 'Etc/GMT+1',
  '+00:00': 'Etc/GMT',
  '-01:00': 'Etc/GMT-1',
  '-02:00': 'Etc/GMT-2',
  '-03:00': 'Etc/GMT-3',
  '-04:00': 'Etc/GMT-4',
  '-05:00': 'Etc/GMT-5',
  '-06:00': 'Etc/GMT-6',
  '-07:00': 'Etc/GMT-7',
  '-08:00': 'Etc/GMT-8',
  '-09:00': 'Etc/GMT-9',
  '-10:00': 'Etc/GMT-10',
  '-11:00': 'Etc/GMT-11',
  '-12:00': 'Etc/GMT-12',
  '-13:00': 'Etc/GMT-13',
  '-14:00': 'Etc/GMT-14',
}

export const isToday = (date: Date, now: Date): boolean => {
  return (
    date.getFullYear() === now.getFullYear() &&
    date.getMonth() === now.getMonth() &&
    date.getDate() === now.getDate()
  )
}

export const getDefaultTimeZone = (): string => Intl.DateTimeFormat().resolvedOptions().timeZone

export const getIanaTimeZone = (timeZone: string): string =>
  ianaTimeZoneMap[timeZone] || getDefaultTimeZone()

export const fromZoned = (date: Date, timeZone: string): Date =>
  fromZonedTime(date, getIanaTimeZone(timeZone))

export const toZoned = (date: Date, timeZone: string): Date =>
  toZonedTime(date, getIanaTimeZone(timeZone))

export const isTodayAccordingToTimeZone = (dateString: string, timeZone: string): boolean => {
  const date = parseISO(dateString)
  const now = new Date()

  const zonedDate = toZoned(date, timeZone)
  const zonedDateNow = toZoned(now, timeZone)

  return isToday(zonedDate, zonedDateNow)
}

export const getDateAccordingToTimeZone = (
  dateString: string,
  timeZone: string,
  formatString: string,
  locale: string = 'en',
): string => {
  const date = parseISO(dateString)
  const zonedDate = toZoned(date, timeZone)

  return format(zonedDate, formatString, { locale: localesMap[locale] })
}

export const getTimeAccordingToTimeZone = (dateString: string, timeZone: string) =>
  getDateAccordingToTimeZone(dateString, timeZone, 'HH:mm')

export const getFullDateAccordingToTimeZone = (
  dateString: string,
  timeZone: string,
  locale: string,
) => getDateAccordingToTimeZone(dateString, timeZone, 'P', locale)

export const getDiffInDaysFromCurrent = (date: string, timeZone: string): number => {
  const zonedDate = toZoned(parseISO(date), timeZone)
  const zonedDateNow = toZoned(new Date(), timeZone)
  const diff = differenceInDays(zonedDateNow, zonedDate)

  // differenceInDays возвращает 0, если прошло меньше суток,
  // но zonedDate могла быть вчера, поэтому возвращаем 1
  if (diff === 0 && !isToday(zonedDateNow, zonedDate)) {
    return 1
  }

  return diff
}

export const isTimeValid = (time: string): boolean => {
  const regex = /^(?:[01]?\d|2[0-3]):[0-5]\d$/
  return regex.test(time)
}
