import cn from 'classnames'

import { Spinner } from '../UI'
import './styles.scss'
import { useEffect, useRef, useState } from 'react'

interface Props {
  open: boolean
  opaque?: boolean
  delay?: number
}

const PageSpinner = ({ open = false, delay = 0, opaque }: Props) => {
  const [shown, setShown] = useState(false)
  const timer = useRef<NodeJS.Timeout>()

  // Хук для исправления мерцающего индикатора, когда его время отображения мало (anti-flickering)
  useEffect(() => {
    const clearTimer = () => {
      if (timer.current) {
        clearTimeout(timer.current)
        timer.current = undefined
      }
    }

    if (open) timer.current = setTimeout(() => setShown(true), delay)
    else setShown(false)

    return () => clearTimer()
  }, [open, delay])

  return shown ? (
    <div className={cn('page-spinner', { opaque })}>
      <Spinner open={shown} />
    </div>
  ) : null
}

export default PageSpinner
