import { TFunction } from 'i18next'

import { Nullable } from 'types'

class TranslationContext {
  #t: Nullable<TFunction> = null // Приватное поле

  constructor(translationFunction?: TFunction) {
    if (translationFunction && typeof translationFunction !== 'function') {
      throw new Error('Translation function must be a function')
    }
    this.#t = translationFunction || null
  }

  get t(): TFunction {
    if (!this.#t) {
      throw new Error('Translation function is not set')
    }
    return this.#t
  }

  set t(translationFunction: TFunction) {
    if (typeof translationFunction !== 'function') {
      throw new Error('Translation function must be a function')
    }
    if (this.#t === null) {
      this.#t = translationFunction
    }
  }
}

export const translationContext = new TranslationContext()
