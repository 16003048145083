import React from 'react'
import { observer } from 'mobx-react-lite'
import { FileInput } from '@telegram-apps/telegram-ui'
import { useTranslation } from 'react-i18next'

import { notification } from 'services'
import { isDefAndNotEmpty } from 'utils'
import { translationContext, useValueContext } from 'contexts'

import { FilesList } from '../FilesList'
import './styles.scss'

const maxFileSizeInMb = 15
const maxFileSize = maxFileSizeInMb * 1024 * 1024 // 15mb

export const AttachedFiles = observer(() => {
  const { t } = useTranslation()
  const { taskStore } = useValueContext()
  const { task, setTaskField } = taskStore
  const files = task?.files

  const onFilesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = Array.from(e.target.files as FileList)
    const validFiles: File[] = []

    selectedFiles.forEach((file) => {
      if (file.size > maxFileSize) {
        notification.error(
          `${translationContext.t(
            'Не удалось загрузить файл',
          )} ${file.name}: ${translationContext.t('размер файла превышает')} ${maxFileSizeInMb} MB`,
        )
      } else {
        validFiles.push(file)
      }
    })

    setTaskField('files', validFiles)
  }

  const onFileDelete = (index: number) => {
    const newFiles = files.filter((_: File[], i: number) => i !== index)
    setTaskField('files', newFiles)
  }

  return (
    <div className="attached-files">
      {isDefAndNotEmpty(files) && <FilesList files={files} onFileDelete={onFileDelete} />}
      <FileInput
        className="attach-file-input"
        label={t('Прикрепить файл')}
        onChange={onFilesChange}
        multiple
      />
    </div>
  )
})
